import { React, useState, useRef } from 'react';
import { videoData } from "../MainPage/Video";
import bck from "../../img/Video_Background.png"

export default function VideoBlock(props) {
    const { id } = props;
    const [modalActive, setModalActive] = useState(false);
    const [mobActive, setMobActive] = useState(false);

    const iframeRef = useRef(null); // Создаем ссылку на iframe

    const handleClick = () => {
        setModalActive(!modalActive);
        setMobActive(!mobActive);
    };

    return (
    <div className="ecosystem-video">
        <ul className="eco-video-list flex list-reset">
            <li className="eco-video-item video-descr">
                <h3 className="video-item-title">
                    {videoData.find(ttl => ttl.id === id).title}
                </h3>
                {mobActive ?
                    <iframe className={ mobActive === true ? "facecast2 active" : "facecast"} src={`${videoData.find(ttl => ttl.id === id).videoLink}?autorun=true`} style={{border:"none"}} allow="autoplay; fullscreen" allowfullscreen></iframe>
                    :
                    <img className={mobActive === true ? "video-img-none" : "video-img"} src={videoData.find(ttl => ttl.id === id).videoImg} alt={videoData.find(ttl => ttl.id === id).alt} onClick={handleClick}></img>
                }
                <p className="video-item-descr">
                    {videoData.find(ttl => ttl.id === id).text1}
                </p>
                <p className="video-item-descr">
                    {videoData.find(ttl => ttl.id === id).text2}
                </p>
                <span className="video-item-descr sign flex">
                    <b>{videoData.find(ttl => ttl.id === id).name}</b>
                    <a>{videoData.find(ttl => ttl.id === id).position}</a>
                </span>
            </li>
            <li className="eco-video-item video-play">
                {modalActive ?
                <iframe className={ modalActive === true ? "facecast active" : "facecast"} src={`${videoData.find(ttl => ttl.id === id).videoLink}?autorun=true`}  style={{border:"none"}} allow="autoplay; fullscreen" allowFullScreen></iframe>
                :
                <img className={ modalActive === true ? "img-none" : "img" }  src={videoData.find(ttl => ttl.id === id).videoImg} alt={videoData.find(ttl => ttl.id === id).alt} onClick={handleClick}></img>
            }  
            </li>
        </ul>
    </div>
  )
}
