import { React, useEffect } from 'react';
import Description from '../components/MainPage/Description';
import Products from "../components/MainPage/Products"
import Advantages from '../components/MainPage/Advantages';
import Ecosystem from '../components/MainPage/Ecosystem';
import Partners from '../components/MainPage/Partners';

export default function Main({ scrollToElement }) {
  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка страницы в начало при монтировании компонента
  }, []);

  return (
    <div>
      <Description scrollToElement={scrollToElement}/>
      <Products />
      <Advantages scrollToElement={scrollToElement}/>
      <Ecosystem />
      {/* <Partners scrollToElement={scrollToElement}/> */}
    </div>
  )
}
