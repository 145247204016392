import React from 'react';

export default function OragenFunc() {
  return (
    <div className="func">
        <div className="func-container">
            <ul className="func-list list-reset flex">
                <li className="func-item flex">
                    <div className=""></div>
                    <div className="func-item-part flex">
                        <h2 className="func-item-title">
                            продуманный функционал 
                            для каждой роли 
                        </h2>
                        <ul className="func-item-dscr flex list-reset">
                            Одновременно в системе могут работать:
                            <li className="item-dscr-item">Медицинские представители, фармпредставители </li>
                            <li className="item-dscr-item">Менеджеры по работе с ключевыми клиентами </li>
                            <li className="item-dscr-item">Медицинские советники</li>
                            <li className="item-dscr-item">Отдел маркетинга </li>
                            <li className="item-dscr-item">Коммерческий департамент </li>
                            <li className="item-dscr-item">Менеджмент и руководители различного уровня</li>
                        </ul>
                    </div>
                    
                </li>
                <li className="func-item func-mob-img">
                    <div className="func-item-img">

                    </div>
                </li>
            </ul>

            <ul className="func-list second list-reset flex" style={{flexWrap: "wrap-reverse"}}>
                <li className="func-item func-mob-img2">
                    <div className="func-item-img2">

                    </div>
                </li>
                <li className="func-item flex" style={{justifyContent: "flex-start"}}>
                    <div className=""></div>
                    <div className="func-item-part flex">
                        <h2 className="func-item-title">
                            ПОМОЩЬ руководству в
                            принятии решений
                        </h2>
                        <ul className="func-item-dscr flex list-reset">
                            Ясная и прозрачная деятельность каждого сотрудника, подробная и детальная отчётность позволяют оперативно принимать решения руководством.
                            <li className="item-dscr-item">Кросс-платформенность – установка на любое устройство: Android, iOS, Windows</li>
                            <li className="item-dscr-item">Создание и выполнение циклового плана под задачи любой кампании</li>
                            <li className="item-dscr-item">Удобное планирование и выполнение визитов к врачу, в ЛПУ, аптеку, гибкая настройка опросников для сбора данных при визите</li>
                            {/* <li className="item-dscr-item">Возможность использования собственной базы данных специалистов, плюс нативная интеграция с валидируемой базой врачей MEDGURU</li> */}
                            <li className="item-dscr-item">Отчётность любой сложности и глубины детализации</li>
                            <li className="item-dscr-item">Быстрая передача данных по API</li>
                            <li className="item-dscr-item">GPS-трекинг</li>
                            <li className="item-dscr-item">CLM-модуль</li>
                            <li className="item-dscr-item"><a href="https://disk.yandex.ru/i/3Wwb-rJayf30Bw" target="_blank" style={{color: "#1D4ED8", textDecoration: "underline"}}><i>Свидетельство на ПО №2024614856 Веб-версия CRM-системы Oragen</i></a></li>
                            <li className="item-dscr-item"><a href="https://disk.yandex.ru/i/E3rwR2k7o-F-PQ" target="_blank" style={{color: "#1D4ED8", textDecoration: "underline"}}><i>Свидетельство о регистрации ПО Мобильное приложение Oragen № 2024613249</i></a></li>
                        </ul>
                    </div>
                    
                </li>
            </ul>

            {/* <div className="oragen-descr oragen-database">
                <h2 className="oragen-text">
                    валидируемая база врачей MEDGURU включает в&nbsp;себя 
                    &nbsp;<i style={{color: "#1D4ED8"}}>&gt;600&nbsp;000 специалистов</i> для вашей эффективной работы
                </h2>
            </div> */}
        </div>
    </div>
  )
}
