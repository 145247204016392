import React, { useRef }  from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function Advantages() {
    const scrollContainerRef = useRef(null);

    const scrollLeft = () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollLeft -= 250; // Измените значение на сколько вы хотите прокрутить влево
      }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollLeft += 250; // Измените значение на сколько вы хотите прокрутить вправо
        }
      };

    return (
        <div id="2" className="advantages">
            <h2 className="advantages-title section-title flex">
                <span>
                    Почему партнеры
                </span>
                <span  style={{ display: 'block' }}>
                    <i className="advantages-title-part" style={{color: "#1D4ED8", paddingLeft: "32vw"}}>
                        Выбирают нас?
                    </i>
                </span>
            </h2>

            <div className="scroll-container flex" ref={scrollContainerRef}>
                <div className="scroll-item flex">
                    <h3>Мы предлагаем эффективное решение полного цикла в связке:</h3>
                    <ul className="scroll-item-list list-reset flex">
                        <li className="scroll-item-point">фармкомпания</li>
                        <li className="scroll-item-point">врач</li>
                        <li className="scroll-item-point">пациент</li>
                    </ul>
                </div>
                <div className="scroll-item">
                    <h3>
                        Располагаем обширной и
                        постоянно обновляемой базой врачей по&nbsp;целому ряду специальностей на&nbsp;рынке
                    </h3>
                </div>
                <div className="scroll-item">
                    <h3>
                        Занимаемся организацией очных
                        и&nbsp;онлайн-медмероприятий любого уровня &laquo;под ключ&raquo;
                        с&nbsp;подбором экспертов и&nbsp;лекторов
                    </h3>
                </div>
                <div className="scroll-item">
                    <h3>
                        Наши технологические решения 
                        в&nbsp;области big data позволяют выполнить анализ любой сложности, кастомизированный под клиентский запрос
                    </h3>
                </div>

                
            </div> 
            <div className='left-rigth-btn flex'>
                <div className="scroll-button scroll-left btn-reset" onClick={scrollLeft}>
                    Scroll Left
                </div>
                <button className="scroll-button scroll-right btn-reset" onClick={scrollRight}>
                    Scroll Right
                </button>
            </div>

        </div>
  )
}
