import {React, useEffect} from 'react';
import "../CSS/oragen.css";
import OragenDescr from '../components/OragenPage/OragenDescr';
import OragenFunc from '../components/OragenPage/OragenFunc';
import Stack from '../components/OragenPage/Stack';


export default function CRM() {
  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка страницы в начало при монтировании компонента
  }, []);

  return (
    <div>
      <OragenDescr />
      <OragenFunc />
      {/* <Stack /> */}
    </div>
  )
}
