import { React, useEffect } from 'react';

export default function Stack() {
  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка страницы в начало при монтировании компонента
  }, []);
  
  return (
    <div className="marquee-container stack-container">
        <h2 className="partners-title container" style={{textTransform: "uppercase"}}>
            наш технологический стек
        </h2>
        {/* <div className="marquee-content">
            <img className="marquee-img flex" src={require('../../img/oragen_stack/Lavarel.png')} alt="Logo 1" />
            <img className="marquee-img flex" src={require('../../img/oragen_stack/Vector-1.png')} alt="Logo 2" />
            <img className="marquee-img flex" src={require('../../img/oragen_stack/Vector.png')} alt="Logo 3" />
            <img className="marquee-img flex" src={require('../../img/oragen_stack/VUE.JS.png')} alt="Logo 4" />
            <img className="marquee-img flex" src={require('../../img/oragen_stack/Битрикс.png')} alt="Logo 5" />
            <img className="marquee-img flex" src={require('../../img/oragen_stack/Lavarel.png')} alt="Logo 1" />
            <img className="marquee-img flex" src={require('../../img/oragen_stack/Vector-1.png')} alt="Logo 2" />
            <img className="marquee-img flex" src={require('../../img/oragen_stack/Vector.png')} alt="Logo 3" />
            <img className="marquee-img flex" src={require('../../img/oragen_stack/VUE.JS.png')} alt="Logo 4" />
            <img className="marquee-img flex" src={require('../../img/oragen_stack/Битрикс.png')} alt="Logo 5" />
        </div> */}

        <div className="oragen-adv add container">
            <ul className="adv-list list-reset flex">
                <li className="adv-list-item">
                    <h3 className="adv-item-title">TypeScript</h3>
                    <p className="adv-item-descr">
                      TypeScript - это язык программирования, основанный на JavaScript, который предоставляет дополнительные функциональности, такие как статическая типизация декларируемых типов данных, классы и модули, чтобы помочь разработчикам создавать более надежные и поддерживаемые программы.
                    </p>
                </li>
                <li className="adv-list-item">
                    <h3 className="adv-item-title">JavaScript и Java.</h3>
                    <p className="adv-item-descr">
                      Он позволяет создавать крупные веб-приложения с использованием декларативного типа данных.
                    </p>
                </li>
                <li className="adv-list-item">
                    <h3 className="adv-item-title">Swift</h3>
                    <p className="adv-item-descr">
                      Язык программирования, разработанный компанией Apple Inc. Используется для написания программного обеспечения под macOS, iOS, watchOS и tvOS.
                    </p>
                </li>
                <li className="adv-list-item">
                    <h3 className="adv-item-title">Java</h3>
                    <p className="adv-item-descr">
                      Язык программирования, разработанный компанией Sun Microsystems (в последующем приобретённой компанией Oracle). Один из самых популярных языков программирования, особенно для разработки приложений под Android.
                    </p>
                </li>
                <li className="adv-list-item">
                    <h3 className="adv-item-title">Ruby</h3>
                    <p className="adv-item-descr">
                      Высокоуровневый язык программирования, предназначенный для удобства и скорости разработки. Часто используется для создания веб-приложений.
                    </p>
                </li>
                <li className="adv-list-item">
                    <h3 className="adv-item-title">Objective-C</h3>
                    <p className="adv-item-descr">
                      Объектно-ориентированный язык программирования, основанный на языке C. Используется для разработки программного обеспечения под операционные системы OS X и iOS.
                    </p>
                </li>
                <li className="adv-list-item">
                    <h3 className="adv-item-title">Objective-C++</h3>
                    <p className="adv-item-descr">
                      Это расширение языка Objective-C, которое позволяет использовать синтаксис C++ в Objective-C.
                    </p>
                </li>
                <li className="adv-list-item">
                    <h3 className="adv-item-title">PHP</h3>
                    <p className="adv-item-descr">
                      Это скриптовый язык программирования общего назначения, который активно применяется для разработки веб-приложений.
                    </p>
                </li>
                <li className="adv-list-item">
                    <h3 className="adv-item-title">React</h3>
                    <p className="adv-item-descr">
                      Библиотека для создания интерактивных пользовательских интерфейсов.
                    </p>
                </li>
                <li className="adv-list-item">
                    <h3 className="adv-item-title">Laravel</h3>
                    <p className="adv-item-descr">
                      Это фреймворк для веб-разработки на языке PHP, который предоставляет инструменты и шаблоны для упрощения разработки.
                    </p>
                </li>
                <li className="adv-list-item">
                    <h3 className="adv-item-title">Vue.js</h3>
                    <p className="adv-item-descr">
                      Это прогрессивный JavaScript-фреймворк для создания пользовательских интерфейсов и одноимённая интерактивная среда разработки (IDE) от компании Microsoft.
                    </p>
                </li>
                <li className="adv-list-item">
                    <h3 className="adv-item-title">«1С-Битрикс: Управление сайтом»</h3>
                    <p className="adv-item-descr">
                      Профессиональная система управления веб-проектами, универсальный программный продукт для создания, поддержки и успешного развития сайтов.
                    </p>
                </li>
            </ul>
        </div>
    </div>
  )
}
